import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    cart: {},
    charge: {},
    current_lang: "en",
    shipping_country: []
  },
  getters: {
    reciever_info: state => {
      let reciever = {};

      if (state.cart.contact) {
        let name = [];
        let address = [];

        // Looping through arrays created from Object.keys
        const entries = Object.entries(state.cart.contact);
        for (const [key, value] of entries) {
          switch (key) {
            case "first_name":
            case "last_name":
              name.push(value);
              break;

            case "email":
              reciever.email = value;
              break;

            case "contact_number":
              reciever.phone = value;
              break;

            default:
              address.push(value);
              break;
          }
        }

        reciever.full_name = name.join(" ");
        reciever.ship_address = address.join(", ");
        return reciever;
      } else {
        return reciever;
      }
    }
  },
  mutations: {
    SET_LANG(state, payload) {
      state.current_lang = payload;
    },
    SET_CART(state, payload) {
      state.cart = payload;
    },
    REMOVE_CART(state) {
      state.cart = {};
    },
    SET_CHARGE(state, payload) {
      state.charge = payload;
    },
    REMOVE_CHARGE(state) {
      state.charge = {};
    },
    REMOVE_SHIPPING_COUNTRY(state) {
      state.shipping_country = null;
    }
  },
  plugins: [vuexLocal.plugin]
});
