<template>
  <div id="change-language">
    <div class="card" style="border-radius: 10px;">
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ $t("change_lang.title") }}</p>
          </div>
        </div>

        <div class="content" style="padding: 1rem;">
          <div class="columns is-multiline hoverable">
            <div class="column is-12" @click="changeLanguageTo('en')">
              <img
                class="lang-icon"
                src="@/assets/langs/en-flag.svg"
                alt="thai language indicator"
              />
              English
            </div>
            <div class="column is-12" @click="changeLanguageTo('zh')">
              <img
                class="lang-icon"
                src="@/assets/langs/zh-flag.svg"
                alt="thai language indicator"
              />
              中文
            </div>
            <div class="column is-12" @click="changeLanguageTo('th')">
              <img
                class="lang-icon"
                src="@/assets/langs/th-flag.svg"
                alt="thai language indicator"
              />
              ไทย
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "change-language",
  methods: {
    ...mapMutations({
      setLang: "SET_LANG"
    }),
    changeLanguageTo: function(selected_lang) {
      this.$i18n.locale = selected_lang;
      this.setLang(selected_lang);
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
#change-language {
  padding: 0 1rem;
}
.lang-icon {
  margin-right: 0.5rem;
}
</style>
