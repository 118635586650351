import en from "./langs/en";
import th from "./langs/th";
import zh from "./langs/zh";
import store from "@/store";

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en,
  th,
  zh
};

const i18n = new VueI18n({
  locale: store.state.current_lang, // set locale
  fallbackLocale: "th", // set fallback locale
  messages // set locale messages
});

export default i18n;
