<template>
  <div id="app">
    <nav-bar />
    <router-view />
    <bos-footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import BosFooter from "@/components/BosFooter.vue";

export default {
  name: "app",
  components: {
    NavBar,
    BosFooter
  }
};
</script>

<style>
#app {
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

pre,
code {
  white-space: normal;
}
.pre-formatted {
  text-indent: -5px;
  white-space: pre-wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hoverable {
  cursor: pointer;
}
.center-cover-bg-image {
  background-position: center;
  background-size: cover;
}

.center-cover-image {
  object-position: center;
  object-fit: cover;
}

.hide_scroll {
  overflow: hidden;
  height: 100%;
}
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #20a6b7;
$primary-invert: findColorInvert($primary);

// Nav Bar
$navbar-item-hover-color: $primary;
$navbar-item-active-color: $primary;

// Links
$link: $primary;

$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  )
);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
