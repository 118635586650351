import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/CartPage.vue")
  },
  {
    path: "/cart/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutPage.vue")
  },
  {
    path: "/cart/completed",
    name: "completed-order",
    component: () =>
      import(
        /* webpackChunkName: "completed-order" */ "../views/CompletedOrder.vue"
      )
  },
  {
    path: "/collection",
    name: "collection",
    component: () =>
      import(
        /* webpackChunkName: "collection" */ "../views/CategoryCollection.vue"
      )
  },
  {
    path: "/collection/:category",
    name: "category-detail",
    component: () =>
      import(
        /* webpackChunkName: "category-detail" */ "../views/CategoryDetailPage.vue"
      )
  },
  {
    path: "/collection/:category/:id",
    name: "product-detail",
    component: () =>
      import(
        /* webpackChunkName: "product-detail" */ "../views/ProductDetailPage.vue"
      )
  },
  {
    path: "/product/:id",
    name: "product-info",
    component: () =>
      import(
        /* webpackChunkName: "product-detail" */ "../views/ProductDetailPage.vue"
      )
  },
  {
    path: "/news/:id",
    name: "news-detail",
    component: () =>
      import(/* webpackChunkName: "news-detail" */ "../views/NewsDetail.vue")
  },
  {
    path: "/special-custom",
    name: "special",
    component: () =>
      import(/* webpackChunkName: "special" */ "../views/SpecialPage.vue")
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/content/terms-and-condition",
    name: "terms-and-condition",
    component: () =>
      import(
        /* webpackChunkName: "terms-and-condition" */ "../views/TermsAndConditions.vue"
      )
  },
  {
    path: "/content/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      )
  },
  {
    path: "*",
    name: "page-not-found",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "../views/NotFoundPage.vue"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
