<template>
  <div id="navbar">
    <b-navbar class="nav" fixed-top shadow>
      <!-- LEFT LOGO -->
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          <img class="nav-logo" src="../assets/bos_logo.svg" alt="Brand Logo" />
        </b-navbar-item>
      </template>
      <!-- RIGHT MENU -->
      <template slot="end">
        <!-- HOME -->
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'home' }"
          :class="{ 'is-active': this.$route.name === 'home' }"
          >{{ $t("navbar.menu_home") }}</b-navbar-item
        >
        <!-- PRODUCTS -->
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'collection' }"
          :class="{
            'is-active':
              this.$route.name === 'collection' ||
              this.$route.name === 'category-detail' ||
              this.$route.name === 'product-detail'
          }"
          >{{ $t("navbar.menu_product") }}</b-navbar-item
        >
        <!-- SPECIAL  -->
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'special' }"
          :class="{
            'is-active': this.$route.name === 'special'
          }"
          >{{ $t("navbar.menu_special") }}</b-navbar-item
        >
        <!-- ABOUT US -->
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'about' }"
          :class="{
            'is-active': this.$route.name === 'about'
          }"
          >{{ $t("navbar.menu_about") }}</b-navbar-item
        >
        <!-- CART -->
        <b-navbar-item tag="router-link" :to="{ name: 'cart' }">
          <b-icon icon="cart" type="is-dark" />
          <b-tag
            v-if="cart.products && cart.products.length > 0"
            type="is-danger"
            :rounded="true"
          >
            <b>{{ cart.products.length }}</b>
          </b-tag>
        </b-navbar-item>
        <!-- LANGUAGES -->
        <b-navbar-item @click="presentChangeLanguage()">
          <div style="padding-top: 0.5rem">
            <div v-if="$i18n.locale === 'th'">
              <img
                class="lang-icon"
                src="../assets/langs/th-flag.svg"
                alt="thai language indicator"
              />
            </div>
            <div v-else-if="$i18n.locale === 'zh'">
              <img
                class="lang-icon"
                src="../assets/langs/zh-flag.svg"
                alt="thai language indicator"
              />
            </div>
            <div v-else>
              <img
                class="lang-icon"
                src="../assets/langs/en-flag.svg"
                alt="thai language indicator"
              />
            </div>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";
export default {
  name: "navbar",
  computed: mapState(["cart"]),
  methods: {
    ...mapMutations({
      setCart: "SET_CART"
    }),
    presentChangeLanguage: function() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ChangeLanguageModal
      });
    }
  },
  mounted() {
    if (!this.cart) {
      this.setCart([]);
    }
  }
};
</script>

<style>
.nav {
  padding: 0.5rem 2.5rem;
}
.nav-logo {
  width: 100px;
  max-height: 4rem !important;
}
.navbar-brand {
  align-items: center !important;
}
.lang-icon {
  width: 32px;
}
</style>
