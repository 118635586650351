export default {
  getCurrentURL: function() {
    return this.isProduction()
      ? "https://blushingowlstudio.com"
      : "https://test.blushingowlstudio.com";
  },
  getBaseURL: function() {
    return this.isProduction()
      ? "https://blushingowlstudio.com/api"
      : "https://test.blushingowlstudio.com/api";
  },
  getApiKey: function() {
    return this.isProduction()
      ? "ADM-z9Cc7pwPZR2cLkm02I65c3GYnoUhiIE1"
      : "ADM-z9Cc7pwPZR2cLkm02I65c3GYnoUhiIE1";
  },
  getOmiseKey: function() {
    // sandbox: pkey_test_5hxbmjcz99xm960c9vj
    return this.isProduction()
      ? "pkey_5kmg42oxh4dw41h9xd0"
      : "pkey_test_5hxbmjcz99xm960c9vj";
  },
  getFirebaseConfig: function() {
    // Your prod web app's Firebase configuration
    const pord_conf = {
      apiKey: "AIzaSyBGdlnic7-UtvuHAj2FpQEVNwukPSWnZIY",
      authDomain: "blushingowl-prod.firebaseapp.com",
      databaseURL: "https://blushingowl-prod.firebaseio.com",
      projectId: "blushingowl-prod",
      storageBucket: "blushingowl-prod.appspot.com",
      messagingSenderId: "928970100999",
      appId: "1:928970100999:web:abe64f3ba592f90032f5a2",
      measurementId: "G-RCXZ0Q561Z"
    };

    // Your testbed web app's Firebase configuration
    const testbed_conf = {
      apiKey: "AIzaSyCRBw_cC_wzUfp_vzJalYnP024tgYba3hw",
      authDomain: "blushingowl-test.firebaseapp.com",
      databaseURL: "https://blushingowl-test.firebaseio.com",
      projectId: "blushingowl-test",
      storageBucket: "blushingowl-test.appspot.com",
      messagingSenderId: "480252524272",
      appId: "1:480252524272:web:5c638ea492738f3990f435",
      measurementId: "G-KCB3X0SE9J"
    };

    return this.isProduction() ? pord_conf : testbed_conf;
  },
  isProduction: function() {
    const hostURL = window.location.hostname;
    return hostURL === "blushingowlstudio.com";
  }
};
