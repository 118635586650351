var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"navbar"}},[_c('b-navbar',{staticClass:"nav",attrs:{"fixed-top":"","shadow":""}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'home' }}},[_c('img',{staticClass:"nav-logo",attrs:{"src":require("../assets/bos_logo.svg"),"alt":"Brand Logo"}})])],1),_c('template',{slot:"end"},[_c('b-navbar-item',{class:{ 'is-active': this.$route.name === 'home' },attrs:{"tag":"router-link","to":{ name: 'home' }}},[_vm._v(_vm._s(_vm.$t("navbar.menu_home")))]),_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'collection' ||
            this.$route.name === 'category-detail' ||
            this.$route.name === 'product-detail'
        },attrs:{"tag":"router-link","to":{ name: 'collection' }}},[_vm._v(_vm._s(_vm.$t("navbar.menu_product")))]),_c('b-navbar-item',{class:{
          'is-active': this.$route.name === 'special'
        },attrs:{"tag":"router-link","to":{ name: 'special' }}},[_vm._v(_vm._s(_vm.$t("navbar.menu_special")))]),_c('b-navbar-item',{class:{
          'is-active': this.$route.name === 'about'
        },attrs:{"tag":"router-link","to":{ name: 'about' }}},[_vm._v(_vm._s(_vm.$t("navbar.menu_about")))]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'cart' }}},[_c('b-icon',{attrs:{"icon":"cart","type":"is-dark"}}),(_vm.cart.products && _vm.cart.products.length > 0)?_c('b-tag',{attrs:{"type":"is-danger","rounded":true}},[_c('b',[_vm._v(_vm._s(_vm.cart.products.length))])]):_vm._e()],1),_c('b-navbar-item',{on:{"click":function($event){return _vm.presentChangeLanguage()}}},[_c('div',{staticStyle:{"padding-top":"0.5rem"}},[(_vm.$i18n.locale === 'th')?_c('div',[_c('img',{staticClass:"lang-icon",attrs:{"src":require("../assets/langs/th-flag.svg"),"alt":"thai language indicator"}})]):(_vm.$i18n.locale === 'zh')?_c('div',[_c('img',{staticClass:"lang-icon",attrs:{"src":require("../assets/langs/zh-flag.svg"),"alt":"thai language indicator"}})]):_c('div',[_c('img',{staticClass:"lang-icon",attrs:{"src":require("../assets/langs/en-flag.svg"),"alt":"thai language indicator"}})])])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }