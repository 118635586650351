<template>
  <div id="BosFooter">
    <footer class="footer">
      <div class="section" style="padding-top: 0; padding-bottom: 1rem;">
        <p>www.BLUSHINGOWLSTUDIO.com</p>
        <p style="padding-top: 0.75rem;">Follow Us</p>
        <div style="padding-top: 0.15rem;">
          <img
            @click="openFacebook()"
            class="lang-icon hoverable"
            src="../assets/socials/social-facebook.svg"
          />
          <span style="padding-right: 1rem" />
          <img
            @click="openInstagram()"
            class="lang-icon hoverable"
            src="../assets/socials/social-instagram.svg"
          />
          <span style="padding-right: 1rem" />
          <b-dropdown aria-role="list" position="is-top-right">
            <img
              slot="trigger"
              role="button"
              class="lang-icon hoverable"
              style="padding-top: 4px"
              src="../assets/socials/social-wechat.svg"
            />
            <b-dropdown-item
              :custom="false"
              style="padding: 0.5rem 1rem; text-align: center;"
            >
              <img src="../assets/socials/qr-wechat.png" />
            </b-dropdown-item>
          </b-dropdown>

          <span style="padding-right: 1rem" />
          <img
            @click="openWeibo()"
            class="lang-icon hoverable"
            src="../assets/socials/social-weibo.svg"
          />
        </div>
      </div>
      <div class="content has-text-centered">
        <img src="../assets/group-payment.svg" alt="paymeny logo" />

        <p class="footer-info">
          {{ $t("footer.title") }} <br />
          {{ $t("footer.subtitle") }}
        </p>
      </div>
      <p class="footer-info">
        <a @click="navigateToTermAndConditions(newsItem)">
          Term & Conditions
        </a>
        |
        <a @click="navigateToPrivacyPolicy(newsItem)">
          Privacy Policy
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "BosFooter",
  methods: {
    openFacebook: function() {
      window.open("https://www.facebook.com/BlushingOwlStudio/");
    },
    openInstagram: function() {
      window.open("http://instagram.com/BlushingOwlStudio");
    },
    openLine: function() {
      window.open("http://line.me/ti/p/~blushingowlstudio");
    },
    openWeibo: function() {
      window.open("https://m.weibo.cn/u/7345571508");
    },
    navigateToTermAndConditions: function() {
      this.$router.push({
        name: "terms-and-condition"
      });
    },
    navigateToPrivacyPolicy: function() {
      this.$router.push({
        name: "privacy-policy"
      });
    }
  }
};
</script>

<style scoped>
.footer-info {
  font-size: clamp(11px, 1vw, 1rem);
}
</style>
